<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Подтвердите действие
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4">
        <ul>
          <li
            v-for="message in blockingMessages"
            :key="message.response.text"
          >
            {{ message.response.text }}
            <ol v-if="message.items && message.items.length">
              <li
                v-for="(item, index) in message.items"
                :key="message.response.text + index"
              >
                {{ item }}
              </li>
            </ol>
          </li>
        </ul>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
            >
              Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="approveQuery"
              :loading="loading"
            >
              Подтвердить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AcceptanceWarnings",
  props: {
    messages: {
      type: Array,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
    routeId: {
      type: String,
      required: true,
    },
    verId: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    tenantId: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: true,
    loading: false,
  }),
  computed: {
    blockingMessages() {
      return this.messages.filter(({ response }) => !response.is_block);
    }
  },
  methods: {
    async approveQuery() {
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
        
      this.loading = true;
      let optionsQuery = {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.aproveRoute',
        params: {
          in_routeId: this.routeId,
          in_verId: this.verId,
          in_date: this.date,
          in_userId: userId,
          in_tenantId: tenantId,
          in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
          in_mode: 2,
        }
      }

      try {
        const resp = await jet.http.post(optionsQuery);
        if (resp.error) {
          throw resp.error;
        }
        const resp1 = await jet.http.post({
          type: 'core-update',
          query: 'sin2:/v:acd2f542-598c-4540-b46c-03375098d467',
          params: [
            { id: 'id',        type: 'id',       value: this.verId },
            { id: 'orderDt',   type: 'date',     value: $moment(this.order.dt).format('YYYY-MM-DD')},
            { id: 'orderNum',  type: 'string',   value: this.order.num },
            { id: 'orderFile', type: 'blob',     value: this.order.file }
          ]
        });
        if (resp1.error) {
          throw resp1.error;
        }
        jet.msg({
          text:  `Утверждение версии ${this.selected.vcroutesVersion} маршрута № ${this.selected.vcroutesRoutecode} ${this.selected.vcroutesRoutename} выполнено успешно!`,
          type: 'warning'
        });
        
      } catch (e) {
        jet.msg({
          text: 'Не удалось утвердить версию маршрута.',
          color: 'warning'
        });
        console.error('AcceptanceWarnings: approveQuery()', e);
        return;
      }
      this.loading = false;
      this.dialog = false;
      this.collection.refresh(this.selected.vcroutesId);
    }
  }
}
</script>

